import RouteErrorElement from "@app/layouts/ErrorLayout/RouteErrorElement";
import { fetchContext } from "@sphtech/web2-core/ssr";
import { RouteObject } from "react-router-dom";

import AboutUs from "../../app/pages/AboutUs/AboutUs";

const errorElement = import.meta.env.PROD ? <RouteErrorElement /> : undefined;

/**
 * Routes specific to HND
 */
export const pubRoutes: RouteObject[] = [
  {
    path: "/about-us",
    element: <AboutUs />,
    errorElement,
    loader: fetchContext,
  },
];
