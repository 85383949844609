import "@sphtech/dmg-design-system/dist/theme-hnd.css";

import { configSite } from "@pub/config";
import { BaseLayout, CustomClassContext } from "@sphtech/dmg-design-system";
import StNewsticker from "@src/app/components/Modules/StNewsticker/StNewsticker";
import { Helmet } from "react-helmet-async";

import { configHeader, hamburgermenuSvgSource } from "./config/configHeader";
import customStyle from "./custom.module.css";
import fontsCssString from "./fonts.css?raw";
import logoSvgSource from "./logo.svg?raw";
import { RoutePaths } from "./routePaths";

type HNDBaseLayoutProps = {
  ArticleTitle?: string;
  children: React.ReactNode;
  ads?: boolean;
  leaderboard?: boolean;
};

/**
 * Reusable Base layout specific to HND.
 */
const HNDBaseLayout = ({
  ArticleTitle,
  children,
  ads = true,
  leaderboard = true,
}: HNDBaseLayoutProps) => {
  const currentYear = new Date().getFullYear();

  return (
    <CustomClassContext.Provider
      value={{
        button: {
          primary: customStyle["door-button-primary"],
          secondary: customStyle["door-button-secondary"],
        },
        divider: {
          secondary: customStyle["fancy-divider"],
        },
        homeContentBlockB: {
          contentBlockContainer: customStyle["home-contentBlock-b"],
        },
      }}
    >
      <BaseLayout
        siteName={configSite.name}
        ArticleTitle={ArticleTitle}
        ads={ads}
        leaderboard={leaderboard}
        NewsTicker={<StNewsticker />}
        RoutePaths={RoutePaths}
        svgSource={logoSvgSource}
        svgHamburgerMenu={hamburgermenuSvgSource}
        mciText={
          <span suppressHydrationWarning>
            MCI (P) 067/12/2023. Published by SPH Media Limited, Co. Regn. No.
            202120748H. Copyright © {currentYear} SPH Media Limited. All rights
            reserved.
          </span>
        }
        socialIconProps={configHeader.socialMedia}
        megaMenuProps={{
          NewsLetterProps: configHeader.megaMenu.newsletter,
          CloseButtonProps: configHeader.megaMenu.closeButton,
          divider: configHeader.megaMenu.divider?.footer,
        }}
      >
        <Helmet>
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.png"
          />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#b92427" />
          <meta name="msapplication-TileColor" content="#b92427" />
          <meta name="theme-color" content="#ffffff" />
          <style type="text/css">{fontsCssString}</style>
        </Helmet>
        {children}
      </BaseLayout>
    </CustomClassContext.Provider>
  );
};

export default HNDBaseLayout;
